@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Ubuntu&display=swap');
*, ::before, ::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box; 
}
.quest-title{
    padding-top: 40px;
    color: #143566;
    font-size: 30px;
}
.quest-subtitle{
    color: #6D6D6D;
    text-align: center;
    font-size: 17px;
    padding-bottom: 30px;
}
.quest-boxborder{
  width: 100%;
  height:auto;
  background: #e4e4e4;
}
.display-card {
  padding: 50px;
  border-radius: 16px;
  color: black;
  font-weight: 500;
}
.display-card span{
  color: #32C0D4;
}
.display-input{
  color: #143566;
  padding-top: 20px;
  font-size: 18px;
  font-weight: 700;
}
.display-input input,select{
  width: 70%;
  padding: 12px 20px;
  margin: 8px 0;
  display:flex;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.display-list {
  list-style: none;
  margin-top: 15px;
  background-color:white ;
  padding: 20px;
  color: #000000;
  border: 2px solid white;
  border-radius: 20px;
  font-size: 15px;
  width: 70%;
}
.display-btn {
    background-color: #32C0D4;
    border: none;
    width: 30%;
    margin-left: 500px;
    margin-top: 50px;
    color: white;
    padding: 16px 24px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 24px;
}
.display-btn:focus {
  background-color: #22838f;
}
.display-question {
  color: #143566;
  padding-top: 20px;
  font-size: 18px;
  font-weight: 700;
}
.display-textfield  {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
}

#radiobtn {
  accent-color: #32C0D4;
}
.result-card{
  text-align: center;
  padding: 3%;
  height: auto;
  width: 95%;
  margin:auto;
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: #e4e4e4;
}
.result-card h2{
  color:#143566;
  font-weight:700;
}
.result-card p{
  width: 100%;
  margin-bottom: 20px;
  color: #000000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.result-card img{
  margin: auto;
}
.result-btn{
  width: 200px;
  height: 60px;
  flex-shrink: 0;
  margin-bottom: 20px;
  border-radius: 10px;
  border: #32C0D4;
  background: #32C0D4;
  color: white;
  font-weight: 700;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
}
.result-btncontainer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Optional: Center the buttons horizontally */
}
@media screen and (max-width: 768px)
{
  .quest-title{
    padding-top: 30px;
    color: #143566;
    font-size: 20px;
  }
  .quest-subtitle{
    color: #6D6D6D;
    text-align: center;
    font-size: 12px;
    padding-bottom: 30px;
    margin: 0 6% 0 6%;
  }
  .quest-boxborder{
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .display-card{
    width: 100%;
    height: 100%;
    padding-top: 20px;
    border-radius: 16px;
    color: black;
    font-weight: 700;
  }
  .display-input{
    color: #143566;
    padding-top: 10px;
    font-size: 13px;
    font-weight: 700;
  }
  .display-input input,select{
    width: 90%;
    padding: 8px 15px;
    margin: 8px 0;
    display:flex;
    font-size: 13px;
    font-weight: 700;
    color: #000000;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  .display-btn{
    width: 50%;
    margin-left: 10px;

  }
  .display-list {
    list-style: none;
    margin-top: 15px;
    background-color:white ;
    padding: 10px;
    color: #000000;
    border: 2px solid white;
    border-radius: 20px;
    font-size: 12px;
    width: 95%;
  }
  .display-question{
    padding-left: 0;
    font-size: 13px;
  }
  .display-text{
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
  }
  .result-card{
    margin: 10px;
    padding-bottom: 30%;
  }
  .result-card h2{
    font-size: 24px;
  }
  .result-card p{
    width: auto;
    height: auto;
    font-size: 16px;
    padding-bottom: 20px;
  }
  .result-btn{
    width: 150px;
    height: 50px;
    font-size: 14px;
  }
 
  .result-img{
    display: block;
    margin:auto;
    width: 50%;
    padding-top: 100px;
  }
}      