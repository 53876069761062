@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&display=swap');

.service{
    background: #fcfcfc;
    min-height: 600px;
    padding-bottom: 30px;
}
.service-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:5px 0 15px 0;
    
}
.service-title > div{
    width:146px;
    height: 3px;
    background-color: #38387f;
}

.service .service-title  h1{
    margin: 0;
    font-size: 96px;
    color: #b5b7bd;
    display: none;
}
.service-title h2{
    margin: 0;
    font-family: 'RecklessNeueSB';;
}
.service-title h2:nth-of-type(1){
    color: var(--primaryblue);
    font-size: 41px;

}
.service-title h2:nth-of-type(2){
    color: var(--cyanblue);
    font-size: 35px;
    
}
.services-center{
    width: 86%;
    margin: 0 auto;
}
.allcard-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px 5rem;
   
}
/* each card */
.card-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 205px;
    height: 222px;
    cursor:pointer;
   
}
.card-container:hover{
    box-shadow: 2px 2px 5px #d0d0d9;
    border-radius: 15px;

    }

.img-card-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 212px;
    max-width: 209px;
}
.card-container img{
    display: block;
    width: 35%;
}

.card-title{
  
    bottom:0px;
    width: 100%;
    text-align: center;
    padding:5px 0;
   
  
}
.card-title h3{
    color:var(--primaryblue);
    margin:0;
    font-size: 18px;
}
.sv-arrow{
    display: none;
 }
 .card-container2{
    padding: 10px;
    height: 138px;
}
.card-container2 p{
    margin: 0;
    font-size: 15px;
    font-family: "lato";
    text-align: center;
    color: #828282;
} 

 .explore-card-btn{
    position: absolute;
    bottom: 20px;
    right: 50%;
    transform: translate(50%, 0);
    width:6rem;
    padding:6px 5px;
    margin:0 auto;
    background-color: white;
    color:rgba(19, 72, 112, 1) ;
    border-radius: 4px;
    border: none;
    font-size: 15px;
    cursor: pointer;
    border:1px solid white;
    box-shadow: 3px 3px 4px #95bbd9 ; 
    /* box-shadow:0 0 5px rgb(155, 155, 158) ; */
 }
.explore-card-btn:hover{
   
   box-shadow: 3px 3px 4px #33668e ;
   transition: ease-in-out 0.3s;
}  

@media screen and (max-width:1200px){
    .card-title{
       
    }
     .sv-arrow{
        display: none;
     }
}

@media screen and (max-width:690px){

    .allcard-container{
            display: flex;
            width: 90%;
            justify-content: left;
            align-items: center;
            overflow-x:auto ;
            scroll-behavior: smooth;
            gap: 20px;
           
           padding:10px 
        
    }

   .card-container{
    min-width: 100%;
    /* min-height: 414px; */
    
   }
   .service .service-title h1 {
    margin: 0;
    line-height: 62px;
    padding: 16px 0;
    font-size: 64px;
}
.service-title h2:nth-of-type(1) {
    font-size: 31px;
    margin-top: 5px;
    text-align: left;
}
.service-title h2:nth-of-type(2) {
    font-size: 31px;
    margin-bottom: 14px;
    margin-top: 5px;
    text-align: left;
}
.service-title h2 {
    font-size: 31px;
}
   .sv-arrow{
    display: block;
    position: absolute;
    height: 30px;
    width: 30px;
    
    z-index: 2;
   }
   .sal{
left: 1px;
   }
   .sar{
       right: 0;
   }
   /* .card-container2{
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    padding-bottom: 5px;
    height: 40%;
   }
   .card-container2 p{
    height: auto;
    font-size: 17px;
    margin-bottom: 4px;
    padding: 0 6px;  
    /* changes */
   /* }
   card-container:nth-child(2) .card-container2 {
    margin-top: 0px;
}  */
}



