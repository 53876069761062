.desc-row:after {
    content: "";
    display: table;
    clear: both;
}
.desc-col {
    float: left;
    width: 33.33%;
    padding:10px; 
}
.desc-col-left{
    float: left;
    width: 60%;
    padding:10px;
}
.desc-col-right{
    width: 40%;
    float: right;
    padding:10px;
}
.desc-imgpos{
    width: 300px;
    height: 250px;
}
.desc-box{
    width: 80%;
    height: auto;
    margin:auto;
    margin-top: 20px;
    padding: 30px;
}
.desc-titlebg{
    width: 100%;
    height: 250px;
    background: #ececec;
}
.desc-title{
    padding-top: 50px;
    padding-left:15px;
}
.desc-title h2{
    text-align: left;
    font-size: 35px;
    color: #143566;
    font-weight: 700;
}
.desc-title p{
    color: #656565;
    font-size: 14px;
}

.desc-types li{
    padding-top: 2px;
    font-size: 14px;
    font-weight: 500;
    color: #656565;
}
.desc-symptoms{
    padding-top: 20px;
}
.desc-symptoms p{
    font-size: 14px;
    font-weight: 500;
    color: #656565;
}
.desc-bg{
    background-image: url("../../assets/questionnaire/descbg.png");
    background-repeat: repeat-y;
    background-size: contain;
}
.desc-card{
    width: 30%;
    height:30%;
    color: #143566;
    background-color: white;
    padding: 20px;
    margin: 10px;
    text-align: center;
    transition: transform .2s;
    border: #000000;
    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2);
}
.desc-card h3{
    color: #000000;
    font-size: 15px;
}
.desc-card p{
    font-size: 12px;
    font-weight: 500;
    color: #143566;
}
.desc-cardsection{
    width: 100%;
    padding:0 15% 20px;
}
.desc-card img{
    width: 160px;
    height: 150px;
    padding: 30px;
    margin: auto;
}
.desc-card:hover{
    transform: scale(1.1);
}
@media (max-width:475px){
    .desc-cardsection{
        max-width: 475px;
    }
}
@media (max-width:640px){
    .desc-cardsection{
        max-width: 640px;
    }
}
@media (max-width:900px){
    .desc-col {
        width: 100%;
        display: block;
        margin-bottom: 20px;
    }
    .desc-col-left, .desc-col-right {
        width: 50%;
        display: inline-block;
        margin-bottom: 20px;
    }
    .desc-imgpos {
        display:none;
    }
    .desc-bg{
        background-image: none;
    }
    .d-flex {
        flex-direction: column; 
    }

    .desc-card {
        margin:auto;
        margin-bottom: 30px;    
    }
    .desc-card h3{
        font-size: 15px;    
    }
    .desc-card p{
        font-size: 12px;
        margin: 5%; 
    }
    .desc-box {
        width: 100%;
        height: auto;
        margin: 10px;
        padding: 20px;
    }

    .desc-titlebg {
        height: auto;
    }

    .desc-title {
        padding-top: 30px;
        font-weight: 400;
        width: auto;
        padding-left: 5px;
    }

    .desc-title h2 {
        font-size: 25px;
        margin: 3%;
    }
    .desc-title p{
        font-size: 12px;
        text-align: justify;
        margin: 3%;
    }

    .desc-types li {
        padding-top: 5px;
        font-size: 12px;
        margin: 0 5% 3% 5%;
        text-align: justify;
    }

    .desc-symptoms p {
        font-size: 12px;
        margin: 3%;
        text-align: justify;
    }
    .desc-types h4{
        font-size: 15px;
    }
    .desc-symptoms h4{
        font-size: 15px;
    }
    .desc-cardsection{
        max-width: 768px;
    }
}
@media (max-width:1068px){
    .desc-cardsection{
        max-width: 1068px;
        padding:0 0 20px;
    }
    
}
@media (max-width:1280px){
    .desc-cardsection{
        max-width: 1280px;
        padding:0 5% 20px;
    }
}

