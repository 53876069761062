.diseases-row:after {
    content: "";
    display: table;
    clear: both;
}
.diseases-col {
    float: left;
    width: 100%;
    padding: 10px;
    height: 240px; 
}
.diseases-left{
    padding-left: 60px;
    width: 25%;
} 
.diseases-right{
    float: right;
    padding-left: 5px;
    width: 25%;
} 
.diseases-middle {
    width: 50%;
    padding-top: 50px;
}
.diseases-titlebg{
    width: auto;
    height: 30%;
    display: flex;
    background: #D9D9D9;
}
.diseases-title{
    color: #143566;
    font-size: 50px;
    text-align: center;   
}
.diseases-heading{
    padding: 20px;
}
.diseases-imgpos{
    text-align: center; 
    height: 200px;
    width: 200px;
}
.diseases-subtitle{
    width: 100%;
    color: #6D6D6D;
    text-align: center;
    font-size: 20px;
}
.diseases-cardbox{
    width: 40%;
    display: inline-block;
    height: 20%;
    padding: 20px;
    margin: 10px 60px 15px;
    border-radius: 10px;
    background-image: url("../../assets/questionnaire/boxbg.png");
    background-size: cover; 
    opacity: 0.9;
    background-repeat: no-repeat;
    transition: transform .2s;
}
.diseases-cardbox:hover{
    transform: scale(1.1);
}
.diseases-cardtitle{
    color: #143566;
    font-size: 25px;
}
.diseases-cardcontext{
    color: #6D6D6D;
    font-size: 15px;
}
.diseases-cardbtn{
    width: 80px;
    height:20px;
    border-radius: 20px;
    background: #32C0D4;
    border: 1px solid #32C0D4;
    color: #FFF;
    text-align: center;
    font-size: 13px;
}
@media (max-width:1200px){
    .diseases-middle {
        height: 10%;
        width: 50%;
    }
    .diseases-bg {
        height: auto;
        width: 100%;
    }
    .diseases-title {
        font-size: 40px;
        padding-top: 0%;
    }
    .diseases-subtitle {
        width:100%;
        font-size: 15px;
    }
    .diseases-imgpos{
        height: 100%;
        width: 100%;
    }
    .diseases-cardbox {
        width: 100%;
        height: auto;
        margin: 10px;
    }
    .diseases-cardtitle {
        font-size: 20px;
    }

    .diseases-titlebg{
        height:30%;
    }
    .diseases-cardcontext {
        font-size: 10px;
    }

}

@media (max-width: 600px) {
    .diseases-middle {
        width: 70%;
        padding-bottom: 5%;
    }
    .diseases-right, .diseases-left {
        height: 0;
        width: 0;
    }
    .diseases-bg {
        height: auto;
        width: 100%;
    }
    .diseases-title {
        font-size: 40px;   
    }
    .diseases-heading{
        padding: 20px;
        font-size: 10px;
    }
    .diseases-subtitle {
        width:100%;
        font-size: 11px;
        padding-bottom: 5%;
    }
    .diseases-imgpos{
        display: none;
    }
    .diseases-cardbox {
        width: 80%;
        height: auto;
        margin: 30px;
    }
    .diseases-cardtitle {
        font-size: 20px;
    }
    .diseases-titlebg{
        height:30%;
    }
    .diseases-cardcontext {
        font-size: 10px;
    }
}
