.p-gry-13{
    color:#666767;
    font-size: 13px;
}
.p-blue-13{
    color:#007ac6;
    font-size:13px;
}

.auth-section{
    /* height: calc(100vh - 77px); */
    margin-top: 30px;
    display: flex;
    margin-left: 10%;
    align-items: center;
    /* background-color: #f8f9fa;
    background:url("https://i.imgur.com/73BxBuI.png"); */
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.auth-container-1{
    margin-right: 30px;
    padding: 20px;
   
}
.auth-container-2{
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 32%;

}
.auth-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 0.055em;
    text-transform: uppercase;
    color: #002A4A;
    text-align: left;
    width: 100%;
    margin-bottom: 18px;
}
.auth-container-2 form{
    width: 100%;
 
    display: flex;
    flex-direction: column;
    /* padding:24px;
    border-radius: 10px;
    box-shadow: 0 10px 24px hsla(0,0%,0%,0.05), 0 20px 48px hsla(0, 0%, 0%, 0.05), 0 1px 4px hsla(0, 0%, 0%, 0.1); */
}
.auth-container-2 form label{
    margin-bottom: 45px;
}
.auth-container-2 form label input{
    padding: 8px 10px;
    width:calc(100% - 23px);
    font-size: 13px;
    border: none;
    border-bottom: 2px solid #134870b4;
}
.auth-container-2 form label input:focus{
 outline: none;
 border-bottom: 2px solid #134870;
}
.auth-container-2 form label:nth-child(1) h4,
.auth-container-2 form label:nth-child(2) h4,
.auth-container-2 form label:nth-child(3) h4,
.auth-container-2 form label:nth-child(4) h4,
.auth-container-2 form label:nth-child(5) h4
{
    margin-top: 10px;
    margin-bottom: 5px;
    color: var(--primaryblue);
}
.auth-container-2 form label:nth-child(){
    display: flex;
}
.auth-container-2 form label:nth-child() input{
    width: 13px;
    margin-right: 10px;
    margin-bottom: 20px;

}
.auth-btn{
    margin:25px 0 5px 0;
    padding:10px 5px;
    background-color: #134870;
    color: white;
    border:solid 1px #134870;
    border-radius: 3px;
    transition: 0.2s;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
}
.auth-btn:hover{
    background-color:rgb(209, 209, 210);
    color: #002A4A;
}
.handle-switch-btn{
    background-color: transparent;
    border: none;
    color:#007ac6;
    font-size: 15px;
    cursor: pointer;
}
.auth-p{
    color: #525252;
}



#logo-container{
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
    
}

.login-logo{
    width: 32px;
    height: 37px;

}

@media screen and (max-width:600px){
    .auth-container-2{
        width: 90%;
    }
}