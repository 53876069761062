 .admin-blog{
    height: auto;
    border: 3px solid #143566;
    padding: 20px;
    border-radius: 10px;
}

.admin-blog .admin-input{
    width: 98%;
    padding: 10px;
}