/* cardio hero */
.cube-hero{
    margin-bottom: 30px;
    background: beige;
}
.cube-hero-container{
    width: 80%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    gap:15px;
}
.ch-title{
    display: flex;
    align-items: center;
    color: rgb(5 37 61);
    gap: 10px;
}
.ch-title h1{
    font-size:5.3vw;
    color: rgb(5 37 61);
    /* background: white; */
    /* border-left: 3px solid rgb(19, 19, 101); */
    padding-left: 5px;
    margin: 0;
}
.ch-title p{
   
    border-bottom: 2px solid;
    font-size: 1.2vw;
}

.ch-img-container{
    width: 148%;
    /* padding: 19px; */
    padding: 20px 51px 20px 51px;
    /* border: 1px solid; */
    border: 2px solid rgb(5 37 61);
    margin-top: 10px;
    /* background: white; */
    background: rgb(9 44 70);
}
.ch-img-container img{
    width: 100%;
}


/* cube testimonials video */
.cubetest{
    background-image: url("../../assets/casset/ctestbg.jpg");
}
.ctest-video-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 30px;
    width: 84%;
    margin:0 auto;
}
.ctest-video-container a{
    width: 57%
}

.ctest-video-container a img{
    width: 100%;
}
.ctest-header{
    width: 300px;
}
.ctest-header img{
    width: 100%;
}

/* cube impulse */
.cim{
    display: none;
}
.cube-imp{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}
.cube-imp >a{
    
    min-height: 285px;
}
.cube >a img{
    width: 100%;
    height: 100%;
}

/* oldman */
.c-oldman{
    margin-bottom: 30px;
}
.c-oldman img{
width: 100%;
}
.c-oldman a:nth-of-type(2){
    display: none;
}
.c-oldman a:nth-of-type(1){
    display: block;
}
/* cexpert */
.c-expert{
    margin-bottom: 30px;
}
.c-expert img{
    width: 100%;
}
.c-expert-container{
    display: none;
}

/* c-appform */
.cube-appform{
    margin-bottom: 30px;
   
}
.cube-app-container{
    width: 90%;
    margin:0 auto;
    background-color:rgba(194, 223, 245, 1) ;
}
.c-app-title img{
    width: 100%;
  }
  .c-app-t1{
      display:none;
  }
.ask-form-container{
    display: flex;
    width: 90%;
    gap: 20px;
    margin: 0 auto;

}
.ask-form-container article{
    flex:1;
}
 
  .form-group input{
    font-size: 20px;
    width: 100%;
    padding: 8px 0px 8px 5px;
}

.cube-app-container{
    width: 90%;
    border-radius: 15px;
}
.cube-app-container form{
    width: 100%;
    padding: 0 ;
    padding-bottom: 18px;
}

.c-app-t1{
    display: block;
    background: rgba(19, 72, 112, 1);
    color: white;
    padding: 10px 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.c-app-t1 h2{
    margin-bottom: 9px;
    font-size: 24px;
    font-family: 'Lato';
}
.c-app-t1 p{
    display: none;
    margin-top: 0;
    text-align: center;
}

.ask-form-container input,select{
    width: 100%;
    padding: 10px 0;
    margin-left: 1px;
    border: 1px solid rgba(19, 72, 112, 1);
    border-radius: 6px;
    font-size: 18px;
}
.ask-form-container article input:focus{
    border: 1px solid rgb(133, 127, 127);
}
.ask-form-container label h4{
    margin-bottom: 8px;
    color: rgba(19, 72, 112, 1);
    font-size: 20px;
    margin-top: 15px;
}

@media screen and (max-width:600px){

/* c-hero */
.cube-hero-container{
    flex-direction: column;

}
.ch-img-container{
    width: 100%;
    padding: 20px;
    margin: 0;
}
.ch-title p{
    font-size: 13px;
    border-left: 2px solid;
    padding-left: 3px;
    border-bottom: 0;

}
.ch-title h1{
    font-size: 31px;
}



/* ctestmonial */
.ctest-video-container{
    flex-wrap: nowrap;
    justify-content: left;
            align-items: center;
            overflow-x:auto ;
            scroll-behavior: smooth;
}
.ctest-video-container::-webkit-scrollbar{
    -webkit-appearance: none;
}
.ctest-video-container a {
    min-width: 306px;
}

/* cimpulse */
.cube-imp >a{
    display: none;
}
.cube-imp{
    background-color: #257DBF;
    padding: 30px 20px 30px 20px;
}
.cim{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.cim > a{
    width: 90%;
    display: block;
    margin: 0 auto;
}
.cim h2{
    font-family: 'Lato';
    color:white;
    font-size: 26px;
    margin-top: 0;
    font-family: "Lato";
}

.cimg{
    width: 100%;
    vertical-align: top;
}
.cim p{
    font-family: 'Lato';
font-style: italic;
font-weight: 400;
font-size: 16px;
line-height: 25px;
/* or 156% */

text-align: center;
letter-spacing: 0.001em;

color: #FFFFFF;
}

.cim button{
    background: #FFFFFF;
border-radius: 5px;
padding: 10px 20px;
color: #134870;
font-size: 18px;
border: white;
display: block;
margin: 0 auto;
}

/* oldman */
.c-oldman img{
    width: 100%;
}
.c-oldman a:nth-of-type(2){
    display: block;
}
.c-oldman a:nth-of-type(1){
    display: none;
}

/* c expert */
.c-expert{
    background-image: url("./Group\ 172.jpg");
    height: 536px;
    display: flex;
    align-items: flex-end;
    background-size: cover;

}
.c-expert>img{
    display: none;
}
.c-expert-container{
    display: flex;
    overflow-x: auto;
    width: 88%;
    margin: 0 auto;
    gap: 19px;
}
.c-expert-container::-webkit-scrollbar{
    -webkit-appearance: none;
}
.c-expert-container img{
    min-width: 90%;
}


/* c-appform */
.cube-app-container{
    width: 100%;
    
}
.c-app-title img{
  display: none;
}
.c-app-t1{
    display: block;
    background: rgba(19, 72, 112, 1);
    color: white;
    padding: 10px 10px;
    
}
.c-app-t1 h2{
    margin-bottom: 9px;
    font-size: 24px;
    font-family: 'Lato';
}
.c-app-t1 p{
    margin-top: 0;
    text-align: center;
}
.ask-form-container{
    flex-direction: column;
    padding: 0 8px;
    width: 87%;
}
form{
    padding: 0;
}
.ask-form-container input{
    width: 100%;
    padding: 10px 0;
    margin-left: 1px;
    border: 1px solid rgba(19, 72, 112, 1);
    border-radius: 6px;
    font-size: 18px;
}
.ask-form-container label h4{
    margin-bottom: 8px;
    color: rgba(19, 72, 112, 1);
    font-size: 20px;
    margin-top: 15px;
}
}

