@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Cinzel:wght@400;500;600&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: GilroyEB;
  src: url("./Gilroy-ExtraBold.otf");
}
@font-face{
  font-family: RecklessNeueSB;
  src:url("./RecklessNeue-SemiBold.ttf")
}
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --borderRadius :5px;
  --cyanblue:#32c0d4;
  --primaryblue:#143566;
  
}
a{
    text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}
.btn {
  cursor: pointer;
  
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transtion);
  text-transform: capitalize;
  display: inline-block;
}
.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}

h1{
  font-size: 28px;
  
}
 h3{
  font-size: 18px;
 
}
 p{
  font-weight: 15px;
  
}