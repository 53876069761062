.remedies-bg{
  background-image: url("../../assets/remedies/remediesbg.png");
  background-repeat: no-repeat;
  background-size: cover; 
}
.remedies-bg h1{
  color:#143566;
  padding: 20px;
  font-size: 35px;
}
.remedies-bg p{
  text-align: center;
  padding-top: 150px;
  font-weight: 700;
  font-size: 30px;
}
.remedies-bg img{
  text-align: center;
  margin:0;
  padding: 0 ;
}
.remedies-imgdiv{
  flex-direction: column;
  align-items: center;
  display: flex;
}
.dremedies-bg{
  background-image: url("../../assets/remedies/remediesdispl.png");
  background-repeat: no-repeat;
  
}
.dremedies-bg h1{
  color:#143566;
  padding: 20px;
  font-size: 35px;
}
.dremedies-h{
  color:#016C4D;
  text-align: center;
  font-weight: 700;
  padding: 20px;
  font-size: 20px;
}
.dremedies-b1{
  text-align: center;
  font-weight: 800;
  padding: 30px;
  font-size: 20px;
}
.dremedies-b2{
  font-weight: 700;
  color: black;
  padding: 30px;
  font-size: 15px;
}
.dremedies-box{
  width: 80%;
  height: auto;
  margin: auto;
  border-radius: 10px;
  border: 47px solid #D1D1D1;
  background: #FFFCFC;
}
.dremedies-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dremedies-btn{
  border-radius: 20px;
  padding: 15px;
  background: #32C0D4;
  border: 1px solid #32C0D4;
  color: #FFF;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}
.dremedies-img img{
  width: 200px;
  height: 200px;
}
.dremedies-btncontainer{
  text-align: right;
  width: 100%;
  padding: 10px;
}
@media (max-width: 1000px){
  .dremedies-b1{
    text-align: center;
    font-weight: 800;
    padding: 20px;
    font-size: 15px;
  }
  .dremedies-b2{
    font-weight: 800;
    padding: 20px;
    font-size: 15px;
  }
  .dremedies-box{
    width: 90%;
  }
  .dremedies-img img{
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 768px) {
  .remedies-bg p{
    text-align: center;
    padding-top: 150px;
    font-weight: 700;
    font-size: 20px;
  }
  .dremedies-box {
    margin-top: 40px;
    width: 90%;
    border-radius: 5px;
    border: 25px solid #D1D1D1;
  }

  .dremedies-row {
    flex-direction: column;
  }

  .dremedies-img img {
    width: 80px;
    height: auto;
  }
  .dremedies-btn {
    font-size: 12px;
  }
  .dremedies-h{
    color:#016C4D;
    text-align: center;
    font-weight: 700;
    padding: 20px;
    font-size: 12px;
  }
  .dremedies-b1{
    text-align: center;
    font-weight: 800;
    padding: 20px;
    font-size: 12px;
  }
  .dremedies-b2{
    font-weight: 800;
    padding: 20px;
    font-size: 10px;
  }
  .dremedies-b2 h3{
    font-size: 12px;
    font-weight: 800;
    text-align: center;
  }
  .dremedies-b2 p{
    text-align: center;
    margin: auto;
    font-size: 8px;
  }
  
}
