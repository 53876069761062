.Home-contact{
    /* padding:10px 20px; */
    background-color: rgba(146, 186, 187, 1);
    display: flex;
    margin: 50px 0;
}
.hc-container1{
    max-width: 40vw;

}
.hc-container1 img{
    width:100%;
}
.hc-container2{
     /* margin:30px 0 0 40px; */
     flex:1;
     display: flex;
     justify-content: center;
     align-items: center;
}



.hc-container2 .hc-title{
     margin-bottom: 15px;

}
.hc-title h2{
    margin:0;
}

.hc-title h2:nth-child(1){
    font-weight: 500;
}


.form-group{
    margin-bottom: 10px;
}
.form-group input{
    width: 100%;
    padding: 8px 6px 8px  ;
    border-radius: 6px;
    border: 1px solid rgb(133, 127, 127);
    font-size: 16px;
    transition: 0.5s;
  outline: none;

}
.form-group input:focus,textarea{
    border: 1px solid rgb(133, 127, 127);
}

textarea{
    width: 100%;
    padding: 5px 6px 8px 7px ;
    border-radius: 6px;
    border: 1px solid rgb(133, 127, 127);
    font-size: 16px;
    color:rgb(133, 127, 127);
    transition: 0.5s;
    outline: none;

}

.hcontact-btn-container{
    display: flex;
    justify-content: space-around;
    margin-top: 31px;
}
.hcontact-btn-container .book-appointment {
    margin: 0;
}
.hcontact-btn-container .whatsapp-btn{
    background: #8db8c2;
    border: none;
    width: 60px;
    border-radius: 50%;

}
.whatsapp-btn img{
    width: 100%;
}
@media screen and (max-width:1000px){
    .hc-title h2{
        font-size: 23px;
    }
    .Home-contact{
        padding: 15px 0px;
        position: relative;
    z-index: 11;
    }
    .hc-container2-child{
        width: 95%;
    }
    .form-group{
        display: flex;
    justify-content: center;
    }
    .hc-container1{
        display: none;
    }
    .form-group input{
        font-size: 20px;
        width: 100%;
        padding: 8px 0px 8px 5px;
    }
    textarea{
        font-size: 20px;
        padding: 5px 0px 8px 7px;
        width:100%
    }


  }

























/* form */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
body{
font-family: 'Poppins';
}
h5{
  font-size: 16px;
}

















