
.apphifi{
    background: #f8f9fa;
}
.hifi-header{
    display: none;
}
.app-header-title{
  
    width: 90%;
}
.app-header-title h1{
 color: #143566;
 font-size: 32px;
}
.app-header-title a{
    font-size: 20px;
    background: wheat;
    padding: 10px;
    border-radius: 10px;
}
.app-header-title a:hover{
    font-weight: 500;
}
.apphf-concern-cardcontainer{
    width: 90%;
    margin: 0 auto;
  
    padding-bottom: 40px;
    padding-top: 20px;
   
    
}
.apphf-c-container1{
    margin-bottom: 30px;
}
.apphf-c-c1-title h3{
    color: #143566;
    font-size: 30px;

}
.apphf-c-c1-cards{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.apphf-c-c1-card{
    width: 40%;
}
.apphfc-card-center{
    display: flex;
    gap: 10px;
    /* background: white; */
    background: #fff;
    border: 2px solid #f8f9fa;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 10px 10px;
    /* background: linear-gradient(90deg, rgba(19,69,102,1) 5%, rgba(19,69,102,1) 34%, rgba(50,192,212,1) 95%); */
}

.apphfc-card-center img{
    width: 81px;
    height: 62px;
    background: #f6c3b7;
    padding: 14px 14px;
    border-radius: 10px;
}

.apphfc-card-center article h4{
    margin: 0;
    font-size: 16px;
    color: #143566;
}

.apphfc-card-center article p{
    color: #868e96;
    font-size: 14px;
    margin-top: 4px;
}

.apphfc-card-center article button{
    margin: 0;
    display: inline;
    color: #052b4a;
    /* margin-top: 7px; */
    /* border: 2px solid #143556; */
    padding: 5px;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    background: var(--cyanblue);
}
.blog{
    border: 3px solid #143566;
    padding: 20px;
    border-radius: 10px;
    max-height: 301px;
    overflow-y: auto;
    white-space: pre-line;
}
.blog::-webkit-scrollbar-thumb{
    background-color: #389AE4;
    border-radius: 0px;
}
.blog::-webkit-scrollbar{
    width: 10px;
}
.blog::-webkit-scrollbar-track{
    box-shadow: inset 0 0 1px rgb(255, 254, 254); 
}

.loading-container{
    width: 100vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}