.consult-hero{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    gap:5%;
    width: 100%;
    margin-bottom: 30px;
}
.consult-hero >div{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* padding: 0px 30px; */
    position: relative;
    gap: 5%;
    width: 100%;
    margin-bottom: 30px;
}
.consult-hero-c1{
    margin-top: 13px;
    width: 44%;
    padding: 10px 28px;
    background: linear-gradient(112.61deg, rgba(19, 72, 112, 0.78) -4.63%, rgba(56, 154, 228, 0.77) 113.6%);
border: 1px solid #FFFFFF;
box-shadow: 4px 4px 10px rgba(19, 72, 112, 0.25);

border-radius: 40px 10px;
color:white;
height: 357px;
padding-bottom: 36px;
}
.consult-hero-c1 h1{
    font-family: 'Trirong';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 54px;
    margin-bottom: 0;
}
.consult-hero-c1 h3{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    margin-top: 0;
}
.consult-hero-c1 p{
    margin: 57px 0px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
}
.consult-hero-c1 .bkapp{
    background: #FFFFFF;
    border: 2px solid #134870;
    box-shadow: 4px 4px 4px rgb(19 72 112 / 25%);
    border-radius: 15px;
    padding: 12px 37px;
    font-size: 19px;
    color:#134870
}
.consult-hero-c1 .bkapp:hover{
    background: #134870;
    color: white;
}
.consult-hero-c1 a{
    font-weight: 600;
    color: #ffffff;
    border-bottom: 2px solid;
}
.consult-hero-c1 a:hover{
    color: #3c3d56
}
.consult-hero .nursephoto{
/* position: absolute; */
width: 26%;
}
.consult-hero a img{
    position: relative;
    width: 95%;
    top: 21px;
}
.consult-hero a{
    width:50%
}

/* .consult-hero a img{
    position: absolute;
} */


/* ------experts---- */

/* .service-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:5px 0 15px 0;
    
}
.service-title > div{
    width:146px;
    height: 3px;
    background-color: #38387f;
}

.service-title > h1{
    margin: 0;
    font-size: 29px;
} */
.experts-title h2{
    font-size: 30px;
    /* margin:0 100px; */
    
    display: inline;
    border-bottom: 3px solid black;
}
.experts-title{
    text-align: center;
}

.experts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:0 50px;
    margin: 10px 0 30px 0;
    gap:20px;
}
.consultpage .consult-hero >a {
    display: none;
}

@media screen and (max-width:600px){
    .consultpage .consult-hero >a {
        display: block;
        width: 100%;
        margin-top: 0;
      
    }
    .consultpage .consult-hero >a img {
        position: relative;
    top: 0px;
        width: 100%;
    }
    .consultpage .experts{
        padding: 0;
    }
    .consultpage .experts>img{
        display: none;
        }
    .consultpage .c-expert-container {
        display: flex;
        overflow-x: auto;
        width: 84%;
        margin: 0 auto;
        gap: 2px;
    }
    .consultpage .c-expert-container img{
        width:100%
    }
    .consultpage .consult-hero>div{
        flex-direction: column-reverse;
    width: 100%;
    padding: 20px 17px;
    background: linear-gradient(112.61deg, rgba(19, 72, 112, 0.78) -4.63%, rgba(56, 154, 228, 0.77) 113.6%);
    border: 1px solid #FFFFFF;
    box-shadow: 4px 4px 10px rgb(19 72 112 / 25%);
    border-radius: 40px 10px;
    color: white;
    margin: 10px 11px;
    }
    .consultpage .consult-hero>div img{
        width: 77%;
        margin-right: 23px;
    }
    .consult-hero-c1{
        width: 100%;
        height: 334px;
        color: white;
        box-shadow: none;
        background: none;
        border: none;
        padding: 0px 9px;
        padding-bottom: 0px;
        text-align: center;
    }
    .consult-hero-c1 h1{
        line-height: 29px;
        text-align: center;
        margin-top: 5px;
        font-size: 26px;
    }
    .consult-hero-c1 h3{
        text-align: center;
        font-size: 21px;
        margin-bottom: 2px;
    }
    .consult-hero-c1 p{
        margin: 7px 0;
        font-size: 19px;
        text-align: center;
    }
    .consultpage .experts-title h2{
        font-size: 24px;
    }
    .experts{
        margin-bottom: 0px;
    }

}
